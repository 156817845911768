<template>
  <div>
    <div>

      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in profileData.academic_qualification"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="12">
            <b-form-group
              label="Qualification Title"
              label-for="name"
            >
              <b-form-input
                id="qualification-name"
                v-model="item.qualification"
                type="text"
                placeholder="Bsc. Computer Science"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Institution"
              label-for="institution"
            >
              <b-form-input
                id="institution"
                v-model="item.institution"
                type="text"
                placeholder="Nairobi university"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Level"
              label-for="Level"
            >
              <v-select
                id="vi-level"
                v-model="item.level"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="levels"
                :selectable="option => !option.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="4">
            <b-form-group
              label="Grade Attained"
              label-for="qualification"
            >
              <b-form-input
                id="qualification"
                v-model="item.grade_or_position"
                type="text"
                placeholder="First Class"
              />
            </b-form-group>
          </b-col>

          <!-- Quantity -->
          <b-col md="2">
            <b-form-group
              label="Cert. No"
              label-for="cert-n0"
            >
              <b-form-input
                id="cert-no"
                v-model="item.reference_number"
                type="text"
                placeholder="A5960"
              />
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="4"
            md="4"
          >
            <b-form-group
              label="Year"
              label-for="year"
            >
              <b-form-input
                id="year"
                v-model="item.date_attained"
                type="date"
                placeholder="Year"
              />
            </b-form-group>
          </b-col>
          <div>

            <b-modal
              :id="`${index + 1}`"
              title="Add Attachment"
              @ok="handleOk(item, file, index + 1)"
            >
              <b-form-group
                label="Attachment"
                label-for="attachment"
              >
                <b-form-file
                  id="attachment"
                  v-model="file"
                  accept=".jpg, .png, .gif, .pdf"
                  placeholder="Attach"
                />
              </b-form-group>
            </b-modal>
          </div>
          <b-col
            lg="9"
            md="8"
          >
            <b-form-group
              label="Attachment"
              label-for="attachment"
            >
              <b-overlay
                :show="item.busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-md-2"
                  @click="showModal(index + 1)"
                >
                  <feather-icon
                    icon="PaperclipIcon"
                    class="mr-25"
                  />
                  {{ item.file }}
                </b-button>
              </b-overlay>
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormFile, BOverlay,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormFile,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: { profileData: {} },
  data() {
    return {
      nextTodoId: 1,
      levels: ['Primary Certificate', 'Secondary Certificate', 'Diploma', 'Certificate', 'Undergraduate Degree', 'Masters Degree', 'PhD Degree'],
    }
  },
  mounted() {
    if (!this.profileData.academic_qualification) {
      this.profileData.academic_qualification = [{ qualification: '', institution: '' }]
    }

    if (this.profileData.academic_qualification && this.profileData.academic_qualification.length) {
      return
    }

    this.initTrHeight()
  },
  created() {
    if (!this.profileData.academic_qualification) {
      this.profileData.academic_qualification = [{ qualification: '', institution: '' }]
    }
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    uploadFile(file, item) {
      const token = localStorage.getItem('accessToken')
      // eslint-disable-next-line no-param-reassign
      item.busy = true
      useJwt.uploadFile({ token, file }).then(response => {
        // eslint-disable-next-line no-param-reassign
        item.file = response.data.message.file_url
        // eslint-disable-next-line no-param-reassign
        item.busy = false
      }).catch(error => alert(JSON.stringify(error)))
    },
    handleOk(item, file, id) {
      console.log(item)
      console.log(file)
      this.uploadFile(file, item)
      const modalId = `${id}`
      console.log(modalId)
    },
    showModal(id) {
      const modalId = `${id}`
      this.$bvModal.show(modalId)
    },
    repeateAgain() {
      this.profileData.academic_qualification.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.profileData.academic_qualification.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
