<template>
  <div>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in profileData.job_application_attachment"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col md="6">
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="file-name"
                v-model="item.document_name"
                type="text"
                placeholder="File Description"
              />
            </b-form-group>
          </b-col>
          <!-- Cost -->
          <b-col md="6">
            <b-form-group
              label="Link To Document"
              label-for="description"
            >
              <b-form-input
                id="file-description"
                v-model="item.document_file"
                type="text"
                placeholder="File Description"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col
            lg="2"
            md="2"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: { profileData: { } },
  data() {
    return {
      nextTodoId: 1,
    }
  },
  mounted() {
    this.initTrHeight()
    this.prePopulate()
    if (!this.profileData.job_application_attachment) {
      this.profileData.job_application_attachment = []
    }
  },
  created() {
    if (!this.profileData.job_application_attachment) {
      this.profileData.job_application_attachment = [{ document_name: '', document_file: '' }]
    }
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    prePopulate() {
      if (this.importQualification && this.importQualification.length) {
        this.profileData.job_application_attachment = this.importQualification.map((item, index) => {
          const filterItem = {}
          filterItem.id = index
          filterItem.document_name = item.document_name
          filterItem.document_file = item.document_file
          return filterItem
        })
      }
    },
    repeateAgain() {
      this.profileData.job_application_attachment.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.profileData.job_application_attachment.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      // this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
