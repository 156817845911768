<template>
  <div>
    <form-wizard
      color="#0F52BA"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account details tab -->
      <tab-content
        title="Basic Details"
        icon="feather icon-file-text"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Basic Details
            </h5>
            <small class="text-muted">
              Enter Your Account Details.(All field are required)
            </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="First name"
              label-for="vi-username"
            >
              <b-form-input
                id="vi-username"
                v-model="profileData.first_name"
                placeholder="john"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Middle name"
              label-for="vi-username"
            >
              <b-form-input
                id="vi-username"
                v-model="profileData.middle_name"
                placeholder="linus"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Last name"
              label-for="vi-username"
            >
              <b-form-input
                id="vi-username"
                v-model="profileData.last_name"
                placeholder="doe"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="vi-email"
            >
              <b-form-input
                id="vi-email"
                v-model="profileData.email_id"
                type="email"
                placeholder="john.doe@email.com"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Phone number"
              label-for="vi-phone"
            >
              <b-form-input
                id="vi-phone"
                v-model="profileData.phone_number"
                type="number"
                placeholder="0722222222"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="KRA pin number"
              label-for="vi-c-pin"
            >
              <b-form-input
                id="vi-c-pin"
                v-model="profileData.kra_pin"
                type="text"
                placeholder="A0D66949036970"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Identification Document"
              label-for="vi-document"
            >
              <v-select
                id="vi-docuument-tu"
                v-model="profileData.id_document_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :selectable="option => ! option.includes('select_value')"
                :options="documentType"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Identification document number"
              label-for="vi-c-document-number"
            >
              <b-form-input
                id="vi-c-document-number"
                v-model="profileData.identification_number"
                type="number"
                placeholder="38490183475"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Advanced Bio Data"
        icon="feather icon-user"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Advanced Bio Data
            </h5>
            <small class="text-muted">Enter Your Personal Info.</small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date of birth"
              label-for="vi-dob"
            >
              <b-form-input
                id="vi-dob"
                v-model="profileData.birth_date"
                type="date"
                placeholder="Date of birth"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Gender"
              label-for="vi-gender"
            >
              <v-select
                id="vi-gender"
                v-model="profileData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="gender"
                :selectable="option => ! option.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NHIF number"
              label-for="vi-c-nhif-number"
            >
              <b-form-input
                id="vi-c-nhif-number"
                v-model="profileData.nhif_number"
                type="number"
                placeholder="83475"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NSSF number"
              label-for="vi-c-nssf-number"
            >
              <b-form-input
                id="vi-c-nssf-number"
                v-model="profileData.nssf_number"
                type="number"
                placeholder="3849"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Postal Address"
              label-for="vi-c-postal-address"
            >
              <b-form-input
                id="vi-c-postal-address"
                v-model="profileData.postal_address"
                type="text"
                placeholder="30100-5633 Nairobi Kenya "
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Home County"
              label-for="vi-c-home-county"
            >
              <b-form-input
                id="vi-c-home-county"
                v-model="profileData.home_county"
                type="text"
                placeholder="Nairobi"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Ethnicity"
              label-for="vi-c-document-number"
            >
              <b-form-input
                id="vi-c-ethnicity"
                v-model="profileData.ethnicity"
                type="text"
                placeholder="Kikuyu"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Sub group"
              label-for="vi-c-sub-group"
            >
              <b-form-input
                id="vi-c-sub-group"
                v-model="profileData.ethnicity_sub_group"
                type="text"
                placeholder="Kikuyu"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- address -->
      <tab-content
        title="Qualifications"
        icon="feather icon-map-pin"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Qualifications
            </h5>
            <small class="text-muted">Fill in your professional and academic  qualifications</small>
          </b-col>
          <b-col md="12">
            <qualification
              :profile-data="profileData"
              @qualification="updateQualification"
            />
          </b-col>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content
        title="Personal Disclosure"
        icon="feather icon-folder"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Personal Disclosure
            </h5>
            <small class="text-muted">Fill in the personal disclosure questions</small>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Have you ever been convicted of any criminal offence or a subject of probation order?"
              label-for="vi-q1"
            >
              <b-form-radio-group
                v-model="profileData.has_been_convicted"
                :options="convicted"
                :aria-describedby="ariaDescribedby"
                name="radios-stacked2"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Details:If yes, State nature of offence, the year and duration of conviction"
              label-for="vi-q1-reason"
            >
              <b-form-textarea
                id="textarea"
                v-model="profileData.has_been_convicted_description"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
          <hr>
          <b-col md="12">
            <b-form-group
              label="Have you ever been dismissed or otherwise removed from any employment?"
              label-for="vi-q1"
            >
              <b-form-radio-group
                v-model="profileData.has_been_dismissed"
                :options="dismissed"
                :aria-describedby="ariaDescribedby"
                name="radios-stacked1"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Details: If yes, give details"
              label-for="vi-q1-reason"
            >
              <b-form-textarea
                id="textarea"
                v-model="profileData.has_been_dismissed_description"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
          <hr>
          <b-col md="12">
            <b-form-group
              label="Are you living with any disability?"
              label-for="vi-q1"
            >
              <b-form-radio-group
                v-model="profileData.living_with_disability"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radios-stacked3"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Details: If yes, give Details /nature of Disability and attach relevant documents"
              label-for="vi-q1-reason"
            >
              <b-form-textarea
                id="textarea"
                v-model="profileData.living_with_disability_description"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- attachements -->
      <tab-content
        title="Attachments"
        icon="feather icon-link"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Attachments
            </h5>
            <small class="text-muted">Attach all documents relevant to this application</small>
          </b-col>
          <b-col md="12">
            <b-form-group
              label=""
              label-for="vi-facebook"
            >
              <document-attachments
                id="vi-facebook"
                :profile-data="profileData"
                placeholder="https://facebook.com/abc"
                @attachments="setAttachments"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Qualification from './qualification/Qualification.vue'
import DocumentAttachments from './attachments/DocumentAttachments.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Qualification,
    BFormRadioGroup,
    BFormTextarea,
    DocumentAttachments,
  },
  data() {
    return {
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      profileData: {},
      documentType: ['National Id', 'Passport'],
      gender: ['Male', 'Female'],
      options: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 0 },
      ],
      convicted: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 0 },
      ],
      dismissed: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 0 },
      ],
    }
  },
  watch: {
    profileData(data) {
      localStorage.setItem('profileData', JSON.stringify(data))
    },
  },
  created() {
    const profileData = localStorage.getItem('profileData')
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (profileData) {
      this.profileData = JSON.parse(profileData)
    }
    this.profileData.email_id = userData.name
  },
  methods: {
    createApplicant() {
      const applicantDetails = this.profileData
      applicantDetails.applicant_name = `${applicantDetails.first_name}  ${applicantDetails.last_name}`
      applicantDetails.academic_qualification = applicantDetails.academic_qualification.map(item => {
        const result = {
          qualification: item.qualification, level: item.level, institution: item.institution, reference_number: item.reference_number, date_attained: item.date_attained, grade_or_position: item.grade_or_position,
        }
        return result
      })
      applicantDetails.job_application_attachment = applicantDetails.job_application_attachment.map(item => {
        const doc = { document_name: item.document_name, document_file: item.document_file }
        return doc
      })
      applicantDetails.user = applicantDetails.email_id

      const token = localStorage.getItem('accessToken')
      useJwt.createJobApplicant({ applicantDetails, token })
        .then(response => {
          localStorage.setItem('profileData', JSON.stringify(response.data.message))
          this.showToast('success', 'Form Submitted', 'EditIcon')
        })
        .catch(error => {
          const message = `Failed to activate ${error}`
          this.showToast('danger', message, 'XCircleIcon')
        })
    },
    formSubmitted() {
      this.createApplicant()
    },
    showToast(variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    updateQualification(qualification) {
      this.profileData.academic_qualification = qualification
    },
    setAttachments(attachments) {
      this.profileData.job_application_attachment = attachments
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  .wizard-card-footer {
    margin-left: 250px !important;
   }
</style>
